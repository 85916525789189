import styled, { css } from 'styled-components';
import { theme } from '../../global/styles/theme';

import { ReactComponent as InputHome } from '../../assets/svgs/home.svg';
import { ReactComponent as InputCustomers } from '../../assets/svgs/customers.svg';
import { ReactComponent as InputPeople } from '../../assets/svgs/users.svg';

interface MenuProps {
  selected: boolean;
  isShowSubMenu?: boolean;
}

export const Container = styled.div`
  height: 100%;
  width: 6.2rem;
  background: #6f549f;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: 10;
`;

export const LogoView = styled.img`
  width: 44px;
  margin-top: 20%;
  margin-bottom: 56%;
`;

export const IconHome = styled(InputHome)`
  width: 24px;
  margin-bottom: 1%;
`;

export const IconCustomers = styled(InputCustomers)`
  width: 24px;
  margin-bottom: 1%;
`;

export const IconPeople = styled(InputPeople)`
  width: 30px;
  height: auto;
  margin-bottom: 1%;
  color: #ffffff;
`;

export const TitleMenu = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: ${theme.colors.white};
`;

export const Menu = styled.div<MenuProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30%;
  cursor: pointer;
  opacity: 0.6;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }

  ${(props: MenuProps) => {
    if (!props.selected) {
      return css`
        opacity: 0.6;
      `;
    }

    return css`
      opacity: 1;

      ::before,
      ::after {
        content: '';
        position: absolute;
        height: 10px;
        width: 18px;
      }

      ::before {
        box-shadow: 69px 10px 0 5px
          ${props.isShowSubMenu ? theme.colors.white : theme.colors.background};
        transform: skewX(-45deg);
      }

      ::after {
        box-shadow: 29px 30px 0 5px
          ${props.isShowSubMenu ? theme.colors.white : theme.colors.background};
        transform: skew(45deg, 0deg);
      }
    `;
  }}
`;
