import FormattedResponseType from '../types/formattedResponse.type';
import ApiVirtus from './api-virtus';

const path = 'companies-profiles';

export const listCompanyProfiles = async (): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await ApiVirtus.get(`${path}/`);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }
  return formattedResponse;
};
