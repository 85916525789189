/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import MultiSelect, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';
import { useAppSelector } from '../../../store';
import CompanyUsersType from '../../../types/companyUsers.type';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FloatFooter from '../../../Components/FloatFooter';

import {
  ButtonContainer,
  ButtonStyled,
  Container,
  Context,
  Dot,
  ErrorSpan,
  GoBackIcon,
  Header,
  InputStyled,
  Label,
  LabelId,
  LoginSpinner,
  NavGoBack,
  NavGoBackContainer,
  NavGoBackName,
  RowForm,
} from './styles';
import {
  createCompanyUser,
  deleteCompanyUser,
  updateCompanyUser,
} from '../../../services/CompaniesUsers.service';
import {
  ButtonStatus,
  ContainerButtonStatus,
  IconPlay,
} from '../../Partners/PartnersDetails/styles';
import CompanyType from '../../../types/company.type';
import { theme } from '../../../global/styles/theme';
import CompanyProfileType from '../../../types/companyProfile.type';
import { listCompanyProfiles } from '../../../services/CompaniesProfiles.service';

interface CompaniesProps {
  value: string;
  label: string;
  isFixed?: boolean;
}

const UsersDetails = () => {
  const navigate = useNavigate();
  const companies = useAppSelector(state => state.companyReducer.companies);
  const company = useAppSelector(state => state.companyReducer.companySelected);
  const loggedUser = useAppSelector(state => state.signInReducer?.user);

  const companyUser = useAppSelector(
    state => state.CompanyUsersReducer?.companyUsers,
  );

  const [name, setName] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState<Array<string>>([]);
  const [idProfile, setIdProfile] = useState('');
  const [externalCode, setExternalCode] = useState('');
  const [email, setEmail] = useState('');
  const [active, setActive] = useState(companyUser.active ?? true);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordCheck, setPasswordCheck] = useState<boolean>();

  const [errors, setErrors] = useState<any>();

  const [companiesList, setCompaniesList] = useState<CompaniesProps[]>([]);
  const [companiesProfilesList, setCompaniesProfilesList] = useState<
    CompaniesProps[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [titleButton, setTitleButton] = useState('Adicionar');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleGoBack = () => {
    navigate('/company-users');
  };

  const isFormValid = () => {
    let formValid = true;
    if (
      name === '' ||
      idProfile === '' ||
      externalCode === '' ||
      email === '' ||
      selectedCompanies.length === 0 ||
      (companyUser._id === '' && (password === '' || !passwordCheck))
    ) {
      formValid = false;
    }
    return formValid;
  };

  const handleUpdateTermsContract = async () => {
    // Validação dos campos
    if (!isFormValid()) {
      setLoading(false);
      return toast.error('É necessário preencher todos os campos');
    }

    const values: CompanyUsersType = {
      _id: companyUser._id,
      name,
      id_group: company.id_group,
      companies: selectedCompanies.map((companieId: string) => {
        return { id: companieId };
      }),
      id_profile: idProfile,
      external_code: externalCode,
      email,
      active,
      password,
      created_by: companyUser.created_by,
    };

    const { status, msg, data } = await updateCompanyUser(
      companyUser._id,
      values,
    );

    setLoading(false);
    if (!status) {
      if (data) {
        return setErrors(data);
        // return msg.forEach((message: string) => toast.error(message));
      }
      return toast.error(msg);
    }

    toast.success('Contrato atualizado com sucesso!');
    return handleGoBack();
  };

  const handleAddTermsContract = async () => {
    // Validação dos campos
    if (!isFormValid()) {
      setLoading(false);
      return toast.error('É necessário preencher todos os campos');
    }

    const values: CompanyUsersType = {
      name,
      id_group: company.id_group,
      companies: selectedCompanies.map((companyId: string) => {
        return { id: companyId };
      }),
      id_profile: idProfile,
      external_code: externalCode,
      email,
      active,
      password,
      created_by: loggedUser._id,
    };

    const { status, msg, data } = await createCompanyUser(
      values,
      loggedUser.id_profile,
    );

    setLoading(false);
    if (!status) {
      if (data) {
        return setErrors(data);
      }
      return toast.error(msg);
    }

    toast.success('Usuário adicionado com sucesso!');
    return handleGoBack();
  };

  const handleDeleteCompanyUser = async () => {
    const { status, msg } = await deleteCompanyUser(companyUser._id);

    if (!status) return toast.error(msg);

    toast.success('Usuário deletado com sucesso!');
    return handleGoBack();
  };

  const handleSubmit = () => {
    setLoading(true);
    if (companyUser._id) return handleUpdateTermsContract();
    return handleAddTermsContract();
  };

  const handleStatus = () => {
    setActive(!active);
  };

  useEffect(() => {
    if (companyUser.active !== active) {
      handleSubmit();
    }
  }, [active]);

  useEffect(() => {
    if (password !== passwordConfirmation) {
      setPasswordCheck(false);
    } else {
      setPasswordCheck(true);
    }
  }, [password, passwordConfirmation]);

  useEffect(() => {
    if (companyUser._id) {
      setTitleButton('Atualizar');
    }
    setName(companyUser.name);
    setSelectedCompanies(
      companyUser.companies.map(
        (companyItem: { id: string }) => companyItem.id,
      ),
    );
    setIdProfile(companyUser.id_profile);
    setExternalCode(companyUser.external_code);
    setEmail(companyUser.email);
    setActive(companyUser.active);
  }, [companyUser, company]);

  useEffect(() => {
    setCompaniesList(
      companies
        .filter(
          (companyItem: CompanyType) =>
            companyItem.id_group === company.id_group,
        )
        .map((comp: CompanyType) => {
          return {
            value: comp._id,
            label: comp.name,
          };
        }),
    );
  }, [companies]);

  useEffect(() => {
    listCompanyProfiles().then((res: any) => {
      if (res.status) {
        setCompaniesProfilesList(
          res.data
            .filter((companyProfile: CompanyProfileType) => {
              const masterProfileId = '60f9ed603a44b1512047172e';
              // @ts-ignore
              if (companyProfile._id === masterProfileId) {
                // eslint-disable-next-line no-unused-expressions
                return loggedUser?.id_profile === masterProfileId;
              }
              return true;
            })
            .map((comp: CompanyProfileType) => {
              return {
                value: comp._id,
                label: comp.name,
              };
            }),
        );
      }
    });
  }, [loggedUser]);

  const colourStyles: StylesConfig = {
    container: (styles: any) => ({
      ...styles,
      marginTop: '0.6rem',
      borderRadius: 8,
      borderWidth: 0.5,
      borderStyle: 'solid',
      borderColor: theme.colors.borderInput,
    }),
    control: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      borderWidth: 0.5,
      borderStyle: 'none',
      ':hover': {
        ...styles[':hover'],
        borderColor: theme.colors.primary,
        boxShadow: `0 0 0 1px ${theme.colors.primary}`,
      },
    }),
  };

  const animatedComponents = makeAnimated();

  return (
    <>
      <FloatFooter>
        <ButtonContainer>
          {companyUser._id && (
            <ButtonStyled variant="secondary" onClick={handleShow}>
              Deletar
            </ButtonStyled>
          )}
          <ButtonStyled
            variant="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <LoginSpinner animation="border" variant="light" />
            ) : (
              titleButton
            )}
          </ButtonStyled>
        </ButtonContainer>
      </FloatFooter>
      <Container>
        <Header>
          <NavGoBackContainer onClick={handleGoBack}>
            <GoBackIcon />
            <NavGoBack>Lista</NavGoBack>
            {name && <Dot />}
            <NavGoBackName>{name || ''}</NavGoBackName>
          </NavGoBackContainer>
          {companyUser._id !== '' && (
            <LabelId>{`ID: ${companyUser._id}`}</LabelId>
          )}
        </Header>
        <Context>
          {companyUser._id !== '' && (
            <ContainerButtonStatus>
              <ButtonStatus variant="secondary" onClick={handleStatus}>
                <IconPlay />
                {active ? 'Desativar' : 'Ativar'}
              </ButtonStatus>
            </ContainerButtonStatus>
          )}
          <RowForm>
            <Label>Nome</Label>
            <InputStyled
              name="name"
              type="text"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
            {errors?.name &&
              errors?.name.map((erro: string) => <ErrorSpan>{erro}</ErrorSpan>)}
          </RowForm>
          <RowForm>
            <Label>E-mail</Label>
            <InputStyled
              name="email"
              type="text"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
            {errors?.email &&
              errors?.email.map((erro: string) => (
                <ErrorSpan>{erro}</ErrorSpan>
              ))}
          </RowForm>
          <RowForm>
            <Label>Senha</Label>
            <InputStyled
              name="password"
              type="password"
              value={password}
              isValid={passwordCheck}
              onChange={(e: any) => setPassword(e.target.value)}
            />
            {!passwordCheck && <ErrorSpan>As senhas não coincidem.</ErrorSpan>}
          </RowForm>
          <RowForm>
            <Label>Confirmação de senha</Label>
            <InputStyled
              name="passwordConfirmation"
              type="password"
              value={passwordConfirmation}
              isValid={passwordCheck}
              onChange={(e: any) => setPasswordConfirmation(e.target.value)}
            />
            {!passwordCheck && <ErrorSpan>As senhas não coincidem.</ErrorSpan>}
          </RowForm>
          <RowForm>
            <Label>Código Externo</Label>
            <InputStyled
              name="externalCode"
              type="text"
              value={externalCode}
              onChange={(e: any) => setExternalCode(e.target.value)}
            />
            {errors?.external_code &&
              errors?.external_code.map((erro: string) => (
                <ErrorSpan>{erro}</ErrorSpan>
              ))}
          </RowForm>
          <RowForm>
            <Label>Perfil</Label>
            <MultiSelect
              theme={(themeSelect: any) => ({
                ...themeSelect,
                colors: {
                  ...themeSelect.colors,
                  primary: theme.colors.primary,
                },
              })}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione o perfil"
              styles={colourStyles}
              closeMenuOnSelect
              components={animatedComponents}
              value={companiesProfilesList.find(
                companyProfile => companyProfile.value === idProfile,
              )}
              options={companiesProfilesList}
              onChange={(e: any) => setIdProfile(e.value)}
            />
            {errors?.id_profile &&
              errors?.id_profile.map((erro: string) => (
                <ErrorSpan>{erro}</ErrorSpan>
              ))}
          </RowForm>
          <RowForm>
            <Label>Empresas</Label>
            <MultiSelect
              theme={(themeSelect: any) => ({
                ...themeSelect,
                colors: {
                  ...themeSelect.colors,
                  primary: theme.colors.primary,
                },
              })}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione as empresas"
              styles={colourStyles}
              closeMenuOnSelect={
                selectedCompanies.length >= companiesList.length - 1
              }
              components={animatedComponents}
              value={selectedCompanies?.map((comp: string) => {
                return companiesList.find(
                  (companyList: CompaniesProps) => companyList.value === comp,
                );
              })}
              isMulti
              options={companiesList}
              onChange={(e: any) =>
                setSelectedCompanies(
                  e.map((companyTmp: CompaniesProps) => companyTmp.value),
                )
              }
            />
            {errors?.companies &&
              errors?.companies.map((erro: string) => (
                <ErrorSpan>{erro}</ErrorSpan>
              ))}
          </RowForm>
        </Context>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Atenção!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Realmente deseja deletar o usuário {name}?</Modal.Body>
          <Modal.Footer>
            <ButtonStyled variant="secondary" onClick={handleClose}>
              Cancelar
            </ButtonStyled>
            <ButtonStyled variant="danger" onClick={handleDeleteCompanyUser}>
              Deletar
            </ButtonStyled>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default UsersDetails;
