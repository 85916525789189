import axios from 'axios';

export const ORIGIN_API = 'panel';

// const baseURL =  'http://localhost:3333', // local
// const baseURL =  'https://api-virtus-dev.plataformavirtus.com.br/', // dev:
const baseURL = 'https://api-virtus-prod.plataformavirtus.com.br/'; // prod

const ApiVirtus = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default ApiVirtus;
