import { combineReducers } from '@reduxjs/toolkit';

import signInReducer from './slices/SignIn/SignIn.slice';
import companyReducer from './slices/Company/Company.slice';
import customerReducer from './slices/Customer/Customer.slice';
import TermsContractReducer from './slices/TermsContract/TermsContract.slice';
import CompanyUsersReducer from './slices/CompanyUsers/CompanyUsers.slice';

export default combineReducers({
  signInReducer,
  companyReducer,
  customerReducer,
  TermsContractReducer,
  CompanyUsersReducer,
});
