import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  IconCustomers,
  IconHome,
  IconPeople,
  LogoView,
  Menu,
  TitleMenu,
} from './styles';

import SubMenu from '../SubMenu';
import Logo from '../../assets/images/icone.png';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleClick = (
    path: string,
    isShowSubMenu: boolean,
    pathDefault: boolean,
  ) => {
    setShowSubMenu(isShowSubMenu);
    if (location.pathname.indexOf(path) !== -1 && !pathDefault) {
      navigate(location.pathname);
    } else navigate(path);
  };

  return (
    <>
      <Container>
        <LogoView src={Logo} />
        <Menu
          onClick={() => handleClick('/home', false, true)}
          selected={location.pathname.indexOf('home') !== -1}
          isShowSubMenu={false}
        >
          <IconHome />
          <TitleMenu>Início</TitleMenu>
        </Menu>
        <Menu
          onClick={() => handleClick('/customers', !showSubMenu, false)}
          selected={location.pathname.indexOf('customers') !== -1}
          isShowSubMenu={showSubMenu}
        >
          <IconCustomers />
          <TitleMenu>Cliente</TitleMenu>
        </Menu>
        <Menu
          onClick={() => handleClick('/company-users', false, false)}
          selected={location.pathname.indexOf('company-users') !== -1}
          isShowSubMenu={showSubMenu}
        >
          <IconPeople />
          <TitleMenu>Usuários</TitleMenu>
        </Menu>
      </Container>
      {showSubMenu && (
        <SubMenu
          handleNavegate={handleClick}
          isShowSubMenu={showSubMenu}
          setShowSubMenu={setShowSubMenu}
        />
      )}
    </>
  );
};

export default Sidebar;
