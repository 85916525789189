import FormattedResponseType from '../types/formattedResponse.type';
import ApiVirtus from './api-virtus';
import CompanyUsersType from '../types/companyUsers.type';

const path = 'companies-users';

export const listCompanyUsers = async (
  cnpj: string,
  page?: number,
  limit?: number,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    if (limit && page) {
      response = await ApiVirtus.get(`${path}/${cnpj}/${page}/${limit}`);
    } else {
      response = await ApiVirtus.get(`${path}/${cnpj}`);
    }

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }
  return formattedResponse;
};

export const getCompanyUsers = async (cnpj: string, companyUserId: string) => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };
  try {
    response = await ApiVirtus.get(`${path}/${cnpj}/${companyUserId}`);
    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }
  return formattedResponse;
};

export const createCompanyUser = async (
  data: CompanyUsersType,
  profileId: string,
) => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await ApiVirtus.post(`${path}/${profileId}`, data);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        if (error.response?.data?.details?.error?.errors) {
          formattedResponse.data = error.response.data.details.error.errors;
        } else {
          formattedResponse.msg = 'Não foi possível conectar a plataforma';
        }
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }
  return formattedResponse;
};

export const updateCompanyUser = async (
  companyUserId: string,
  data: CompanyUsersType,
) => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await ApiVirtus.put(`${path}/${companyUserId}`, data);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        if (error.response?.data?.details?.error?.errors) {
          formattedResponse.data = error.response.data.details.error.errors;
        } else {
          formattedResponse.msg = 'Não foi possível conectar a plataforma';
        }
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }
  return formattedResponse;
};

export const deleteCompanyUser = async (companyUserId: string) => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await ApiVirtus.delete(`${path}/${companyUserId}`);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }
  return formattedResponse;
};
