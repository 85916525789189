import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import {
  AlertTable,
  AreaPagination,
  ButtonFilter,
  Container,
  Context,
  DescriptionPagination,
  Header,
  PaginationStyled,
  TableStyled,
  Td,
  Th,
  THead,
  Tr,
} from './styles';
import TitlePage from '../../Components/TitlePage';
import SkeletonListEndBar from '../../Components/SkeletonListEndBar';
import formatDate from '../../utils/formatDate';
import { useAppDispatch, useAppSelector } from '../../store';
import { listCompanyUsers } from '../../services/CompaniesUsers.service';
import { selectCompanyUsers } from '../../store/slices/CompanyUsers/CompanyUsers.slice';
import CompanyUsersType from '../../types/companyUsers.type';

const initialUser: CompanyUsersType = {
  _id: '',
  name: '',
  id_group: '',
  companies: [{ id: '' }],
  id_profile: '',
  external_code: '',
  email: '',
  active: true,
  password: '',
  created_by: '',
};

const Users: React.FC = () => {
  const HOME_PAGE = 1;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company = useAppSelector(state => state.companyReducer.companySelected);

  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<CompanyUsersType[]>([]);

  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [paginationItem, setPaginationItem] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(HOME_PAGE);
  const [descriptionPagination, setDescriptionPagination] = useState('');
  const [pageInit, setPageInit] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);

  const handleViewUserDetail = (user: CompanyUsersType) => {
    dispatch(selectCompanyUsers(user));
    navigate('/company-users/details');
  };

  const changePage = (page: number) => {
    if (page > 0 && page <= paginationItem.length) {
      if (page > 5) {
        setPageInit(page - 5);
        setPageLimit(page);
      } else {
        setPageInit(0);
        setPageLimit(5);
      }

      setSelectedPage(page);
      // getAllContracts(page, CONTRACTS_PER_PAGE);
    }
  };

  useEffect(() => {
    setLoading(true);
    listCompanyUsers(company.cnpj, selectedPage, pageLimit).then(res => {
      if (res.status) {
        setLoading(false);
        setUsers(res.data.companyUsers);
        setPaginationItem(
          Array.from(Array(Math.ceil(res.data.total / pageLimit)).keys()),
        );
        setTotalUsers(res.data.total);
      }
    });
  }, [selectedPage]);

  useEffect(() => {
    setDescriptionPagination(
      `${(selectedPage - 1) * pageLimit + users.length - (users.length - 1)}-${
        (selectedPage - 1) * pageLimit + users.length
      } de ${totalUsers} Usuários`,
    );
  }, [users.length, selectedPage, totalUsers]);

  return (
    <Container>
      <Header>
        <TitlePage title="Lista de Usuários" />
        <ButtonFilter
          variant="primary"
          onClick={() => handleViewUserDetail(initialUser)}
        >
          Adicionar usuário
        </ButtonFilter>
      </Header>
      <Context>
        {loading && <SkeletonListEndBar />}
        {!loading && (
          <TableStyled hover>
            <THead>
              <tr>
                <Th>ID</Th>
                <Th>Nome</Th>
                <Th>E-mail</Th>
                <Th>Ativo</Th>
                <Th>Última Atualização</Th>
              </tr>
            </THead>
            <tbody>
              {users.map((user: any) => (
                // eslint-disable-next-line no-underscore-dangle
                <Tr key={user._id} onClick={() => handleViewUserDetail(user)}>
                  {/* eslint-disable-next-line no-underscore-dangle */}
                  <Td>{user._id}</Td>
                  <Td>{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.active ? 'Sim' : 'Não'}</Td>
                  <Td>{formatDate(user.updatedAt || '')}</Td>
                </Tr>
              ))}
            </tbody>
            {users.length > 0 && (
              <tfoot>
                <tr>
                  <td colSpan={5}>
                    <AreaPagination>
                      <DescriptionPagination style={{ visibility: 'hidden' }}>
                        {descriptionPagination}
                      </DescriptionPagination>
                      <PaginationStyled size="sm">
                        <Pagination.First
                          onClick={() => changePage(HOME_PAGE)}
                        />
                        <Pagination.Prev
                          onClick={() => changePage(selectedPage - 1)}
                        />
                        {paginationItem
                          .slice(pageInit, pageLimit)
                          .map((page: number) => (
                            <Pagination.Item
                              key={page}
                              active={page + 1 === selectedPage}
                              onClick={() => changePage(page + 1)}
                            >
                              {page + 1}
                            </Pagination.Item>
                          ))}
                        <Pagination.Next
                          onClick={() => changePage(selectedPage + 1)}
                        />
                        <Pagination.Last
                          onClick={() => changePage(paginationItem.length)}
                        />
                      </PaginationStyled>
                      <DescriptionPagination>
                        {descriptionPagination}
                      </DescriptionPagination>
                    </AreaPagination>
                  </td>
                </tr>
              </tfoot>
            )}
          </TableStyled>
        )}
        {!loading && users.length <= 0 && (
          <AlertTable>Nenhum usuário encontrado</AlertTable>
        )}
      </Context>
    </Container>
  );
};

export default Users;
