import { format, getDate, setDate } from 'date-fns';
import isDate from './isDate';
import ptBR from './locale';

const formatDate = (date: string) => {
  if (!isDate(date)) return '';

  let newDate = new Date(date.indexOf('T') !== -1 ? date.split('T')[0] : date);

  newDate = setDate(newDate, getDate(newDate) + 1);

  return format(newDate, "dd 'de' MMMM", {
    locale: ptBR,
  });
};

export default formatDate;
