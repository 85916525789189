/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CompanyUsersType from '../../../types/companyUsers.type';

export interface CompanyUsersState {
  companyUsers: CompanyUsersType;
}

const initialState: CompanyUsersState = {
  companyUsers: {
    _id: '',
    name: '',
    id_group: '',
    companies: [{ id: '' }],
    id_profile: '',
    external_code: '',
    email: '',
    active: true,
    password: '',
    created_by: '',
  },
};

export const companyUsersSlice = createSlice({
  name: 'companyUsers',
  initialState,
  reducers: {
    selectCompanyUsers: (state, action: PayloadAction<CompanyUsersType>) => {
      state.companyUsers = action.payload;
    },
  },
});

export const { selectCompanyUsers } = companyUsersSlice.actions;

export default companyUsersSlice.reducer;
